<template>
    <header>
        <div class="wrapper container-fluid">
            <div class="row">

                <div id="logo" class="col-xs-9 col-sm-4">
                    <router-link to="/">
                        <img alt="Gigampfi Logo" v-if="!toggledNav" src="../assets/logo.svg">
                        <img alt="Gigampfi Logo" v-else src="../assets/logo_sujet.svg">
                    </router-link>
                </div>

                <div class="col-xs-3 col-sm-8">
                    <div id="nav" v-bind:class="{ 'show': toggledNav }">
                        <ul class="menu-main">
                            <li class="dropdown">
                                <!--<a class="dropbtn nolink blue">Spielgruppe</a>-->
                                <router-link class="blue nonactive" to="#">Spielgruppe</router-link>
                                <div class="dropdown-content blue">
                                    <router-link class="blue" to="/informationen">Informationen</router-link>
                                    <router-link class="blue" to="/paedagogik">Spielgruppenpädagogik</router-link>
                                    <router-link class="blue" to="/geschichte">Geschichte</router-link>
                                    <router-link class="blue" to="/standorte">Standorte</router-link>
                                    <!--<router-link class="blue" to="/anmeldung">Anmeldeformular</router-link>-->
                                </div>
                            </li>
                            <li class="dropdown">
                                <!--<a class="dropbtn nolink green">Team</a>-->
                                <router-link class="green nonactive" to="#">Team</router-link>
                                <div class="dropdown-content">
                                    <router-link to="/leiterin/rosanna-muff">Rosanna Muff</router-link>
                                    <router-link to="/leiterin/martina-ineichen">Martina Ineichen</router-link>
                                    <!--<router-link to="/leiterin/hedije-zenelaj">Hedije Zenelaj
</router-link>-->
                                    <router-link to="/leiterin/tanja-furrer">Tanja Furrer</router-link>
                                    <router-link to="/leiterin/irene-yildiz">Irene Yildiz</router-link>
                                    <router-link to="/leiterin/sabrina-buesser">Sabrina Büsser</router-link>
                                    <router-link to="/leiterin/karolis-wuest">Karolis Wüst</router-link>
                                    <router-link to="/leiterin/marina-portmann">Marina Portmann</router-link>
                                </div>
                            </li>
                            <li><router-link class="red" to="/aktuelles">Aktuelles</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="toggle-menu-wrapper">
                    <button type="button" id="toggle-menu" class="toggle-menu" v-bind:class="{ 'toggled': toggledNav }"
                        @click="toggledNav=!toggledNav">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                </div>

            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    props: {
        msg: String
    },
    data () {
        return {
            toggledNav: false
        }
    },
    methods: {
        /*toggle: function(selector){
            $(selector).collapse('hide')
            //return require('../assets/img/' + selector)
        }*/
    },
    watch: {
        '$route' () {
            // if page changes, close menu
            this.toggledNav = false
        },
        // whenever active changes, this function will run
        toggledNav: function () {
            this.toggleBodyClass(this.toggledNav, 'has-overlay')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
